import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Shopify_top from "../images/shopify/shopify_top.jpg"
import Process__Step_Sr1 from "../images/shopify/def_approach.png"
import Process__Step_Sr2 from "../images/shopify/best_aaproch.png"
import Process__Step_Sr3 from "../images/process-step.jpg"
import Retro from "../images/shopify/retrodays.png"
import Migration_shopify from "../images/shopify/migrate_shopify.jpg"
import Reco from "../images/shopify/reco.jpg"
import Retro_mobile from "../images/shopify/retro_mobile.png"
import Basel from "../images/shopify/basel.png"
import Shopify_plus from "../images/shopify/shopify_plus.png"
import Shopify_build from "../images/shopify/shopify-reviews.jpg"
import Shopify_batch from "../images/shopify-expert.png"
import {Helmet} from "react-helmet"

export default class Container extends Component {

  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }
  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Custom Shopify Development for B Corps, Planet Friendly & Purpose Driven Brands</title>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
              <link rel="canonical" href={this.state.url} />
              <meta name="description" content="CueForGood offers Custom Shopify Plus development, Shopify 2.0 Migration and Store set up & migration to Shopify from other platforms. We offer Shopify services to a global audience and love working with Purpose driven companies & Sustainable brands." />
              <meta property="og:site_name" content="CueForGood" />
              <meta property="fb:app_id" content="289086684439915" />
              <meta property="og:url" content={this.state.url} />
              <meta property="og:title" content="Custom Shopify Development for B Corps, Planet Friendly & Purpose Driven Brands" />
              <meta property="og:description" content="CueForGood offers Custom Shopify Plus development, Shopify 2.0 Migration and Store set up & migration to Shopify from other platforms. We offer Shopify services to a global audience and love working with Purpose driven companies & Sustainable brands." />
              <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:description" content="CueForGood offers Custom Shopify Plus development, Shopify 2.0 Migration and Store set up & migration to Shopify from other platforms. We offer Shopify services to a global audience and love working with Purpose driven companies & Sustainable brands." />
              <meta name="twitter:title" content="Custom Shopify Development for B Corps, Planet Friendly & Purpose Driven Brands" />
              <meta name="twitter:site" content="@CueForGood" />
              <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
        </Helmet>
        <article className="static_page shopify-page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <h1 className="heading_main">shopify
              <img
                  src={Shopify_batch}
                  alt="Magento"
                  className="digital-batch shopify-batch"
                />
              </h1>
              <div className="ser_sec_row_lt wdt-40 pd_right_40">
                <div className="ser_sec_row_lt_text">
                  <p>
                   Customers are no longer buying just things, they are buying experiences. In the online world, experiences are created by the ease of
                    accessibility and the ability to provide customized solutions to the customers’ problems. Leveraging one of the biggest & 
                    fastest-growing eCommerce platforms, Shopify, our team of developers follows industry best practices to build and manage your
                     Shopify Store to serve as a cost-effective home base for your business. Whether you want to build on top of your existing store or start
                      your online store from scratch, using the multi-functional apps provided by the platform, we create intuitive & interactive eStores that 
                      are easy-to-manage for the developers & easy-to-navigate for your customers.
                  
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center ">
                <img
                  src={Shopify_top}
                  alt="CueForGood "
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">OUR APPROACH</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1}
                        alt=""
                        className="image_responsive"
                      />
                    </figure>
                    <h3>defined approach</h3>
                    <p>
                      Our team of highly-skilled Shopify certified developers define and implement an efficient 
                      process that ensures streamlined communication resulting in a smooth development course.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt=""
                        className="image_responsive"
                      />
                    </figure>
                    <h3>best practice adherence</h3>
                    <p>
                      There are several ways of formulating a solution. We choose the one that complies with both the general web development standards and Shopify guidelines. 
                      This ensures that each process is executed in a streamlined fashion giving optimal resolutions for all technical problems.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt=""
                        className="image_responsive"
                      />
                    </figure>
                    <h3>consultative approach</h3>
                    <p>
                      Using our experience with the eCommerce industry, we offer the best consultations that are focused on the up and coming trends in the eCommerce world.
                       Let’s just say that we like to think outside the box and give you a unique solution to all your website related concerns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex build">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <strong className="highlight-span">shopify/allthatgrows</strong>
                    <img
                      src={Retro}
                      alt="Retro-style AllThatGrows website built on Shopify"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">shopify build from scratch</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Offering the ease of quick setup, Shopify has helped us create and manage e-stores for a number of our clients over the course of 
                      the years and is still helping us build high-quality e-stores from scratch. <br />
                      <br />
                     Write to us to know how Shopify can help facilitate your e-store.
                    </p>
                  </div>
                  <Link to="/contact/" id="first-focus-element" className="btn main_cta focus-visible">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right">
                  <figure>
                    <img
                      src={Migration_shopify}
                      alt="migration to shopify from a different platform"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">
                    migration to shopify from a different platform
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Looking for a secure platform that is easy-to-manage and lets your business grow to its full potential? Look no further! Shopify is the eCommerce 
                      platform you are searching for. Whether you are on a self-hosted non-turnkey platform looking for an easy-to-manage alternative, or a turnkey,
                       hosted platform looking for a solution that is secure and scales with your growth, Shopify gives you a wide variety of modules, functionalities,
                        and features to choose from. 

                      <br />
                      <br />
                     Send us a message to know more about Shopify and its features. 
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex remarketing">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Reco}
                      alt="Recommended content section on CueForGood website"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    shopify performance audit and optimization
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      With the evolution of technology, we tend to leave a lot of the important things on our to-do lists at the mercy of devices and software. 
                      However, a skilled individual can make all the difference when it comes to identifying and fixing any loopholes especially when the performance
                       of your website is concerned. We have a whole team of highly-skilled developers to take care of that for you. 
                       <br />
                      <br />
                    Get a performance audit of your e-store on Shopify. Drop us a line. 
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="conpetulatization ">
            <div className="container">
              <div className="all-grows-block">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">shopify apps integration</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      The Shopify marketplace offers a range of apps and pre-built modules that can help you give your customers, a user experience that they will not easily forget. The more user-friendly your store, the more they will talk about it in their social circles. 
                      Don’t know what will work best for your e-store? Get in touch with us.
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    Get in touch
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60 hide-mob">
                  <img
                    src={Retro_mobile}
                    alt="Retro-style AllThatGrows website built on Shopify - Mobile View"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Basel}
                      alt="shopify theme integration"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">shopify theme integration</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                     An elegant, intuitive, and stand-out design that coincides with your products serves as the foundation on which user experience is built.
                       We help you pick the most suitable theme for your e-store that will look aesthetically pleasing while offering all the required features to your customers. 
                       <br />
               
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right">
                  <figure>
                    <img
                      src={Shopify_plus}
                      alt="Showcase of Shopify Plus features for high-growth businesses"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40 fleex">
                  <h2 className="heading_main">shopify plus</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      While Shopify gives you quick development and easy management for your e-store, Shopify Plus gives you all that and more.
                       Shopify Plus is an enterprise of one of the biggest eCommerce platforms, Shopify but with more customizable modules, functionalities,
                        and features for your website while allowing your business enough room to grow. 
                         <br />
                      <br />
                      Want to try Shopify Plus? Get in touch with us
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  last_product fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Shopify_build}
                      alt="Custom Shopify store development with advanced features"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">Custom Shopify Development</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      If you have no customers, the chances of your business flourishing are quite slim. To catch-up with their dynamic demands, your business needs to be
                       in sync with what they need and then provide them with customized solutions. Shopify allows you to do just that - build custom functionalities on top of your existing e-store with ease. 
                       <br />
                      <br />
                     To make it easier still, get in touch with us. 
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

        </article>
      </Layout>
    )
  }
}
